<template>
  <div id="background" :style="{ backgroundImage: 'url(' + imagepath() + ')'  }">
    <Header color="primary" />

    <div class="wrapper">
      <div id="content">
        <v-container >
          <v-row align="center" justify="center" >
            <v-col cols="12" lg="9" md="10" xl="8" :style="{ backgroundColor: getBackgroundColor() }">
              <v-card align="center" class="text-center" color="transparent" elevation="0">
                <v-card-title align="center" class="justify-center primary--text"
                              style="font-family: Cherolina; font-size: 80px;">
                  Trauung
                </v-card-title>
                <v-card-text class="text-center primary--text" style="font-family: Raleway;  font-size: large; padding-top: 15px">
                  Die zivile Trauung findet am 03. Februar 2023 um 14:00 Uhr im Standesamt in Tircal 11 in Domat/Ems statt. <br/><br/> Wir bitten euch die öffentlichen Parkplätze unterhalb des Gemeindehauses zu benutzen.
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row align="center" justify="center">
            <v-col cols="12" lg="4" md="5" xl="4" :style="{ backgroundColor: getBackgroundColor() }">
              <iframe
                  allowfullscreen="true"
                  loading="lazy"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2729.4207608339834!2d9.4529584!3d46.835406400000004!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4784c6a5039bb901%3A0x77aa564e36ba16d0!2sTircal%2011%2C%207013%20Domat%2FEms!5e0!3m2!1sde!2sch!4v1670521846278!5m2!1sde!2sch"
                  style="border:0; height: 45vh; opacity: 0.9"
                  width="100%">
              </iframe>
            </v-col>
            <v-col cols="12" lg="4" md="5" xl="4" :style="{ backgroundColor: getBackgroundColor() }">
              <iframe
                  allowfullscreen="true"
                  loading="lazy"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d682.3612786190957!2d9.452447269697378!3d46.83492691564889!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xc58c2c0f0d7a69b!2zNDbCsDUwJzA1LjciTiA5wrAyNycxMS4xIkU!5e0!3m2!1sde!2sch!4v1671369168807!5m2!1sde!2sch"
                  style="border:0; height: 45vh; opacity: 0.9"
                  width="100%">
              </iframe>
            </v-col>
          </v-row>
          <v-row align="center" justify="center">
            <v-col cols="12" lg="9" md="10" xl="8" :style="{ backgroundColor: getBackgroundColor() }">
              <v-card align="center" class="text-center" color="transparent" elevation="0">
                <v-card-title align="center" class="justify-center primary--text"
                              style="font-family: Cherolina; font-size: 80px; ">
                  Apéro
                </v-card-title>
                <v-card-text class="text-center primary--text" style="font-family: Raleway; font-size: large; color: white; padding-top: 15px" >Im Anschluss an die Trauung möchten wir gerne mit euch bei einem Apéro anstossen. Dafür werden wir uns ins Garten Hotel Sternen in Domat/Ems begeben.
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row align="center" justify="center">
            <v-col cols="12" lg="9" md="10" xl="8" :style="{ backgroundColor: getBackgroundColor() }">
              <iframe
                  allowfullscreen="true"
                  loading="lazy"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2729.338915769073!2d9.451206476445407!3d46.837017771129744!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4784c6a492f0001f%3A0x6ab4ea4ffbda7168!2sGarten%20Hotel%20Sternen!5e0!3m2!1sde!2sch!4v1671094389808!5m2!1sde!2sch"
                  style="border:0; height: 45vh; opacity: 0.9"
                  width="100%">
              </iframe>
            </v-col>
          </v-row>
        </v-container>
      </div>
      <Footer col="primary"/>
    </div>
  </div>
</template>

<script>
import header from "../Header/Header";
import footer from "../Footer/Footer";
import sharedfunctions from "../../sharedfunctions";

export default {
  name: "Standesamt",
  components: {
    'Header': header,
    'Footer': footer,
  },
  methods: {
    imagepath() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return require("../../assets/backgrounds/pexels-teona-swift-6913378.jpg");
        case 'sm':
          return require("../../assets/backgrounds/pexels-teona-swift-6913378.jpg");
        case 'md':
          return require("../../assets/backgrounds/pexels-cottonbro-studio-4273467.jpg");
        case 'lg':
          return require("../../assets/backgrounds/pexels-cottonbro-studio-4273467.jpg");
        case 'xl':
          return require("../../assets/backgrounds/pexels-cottonbro-studio-4273467.jpg");
      }

    },
    getBackgroundColor() {
      if(this.isMobile()) {
        return 'rgba(221,222,227,0.6)'
      } else {
        return 'rgba(174,177,168,0.8)'
      }
    },
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    initColorScheme() {
      if (this.isMobile()) {
        this.updateDarkMode(false);
      } else {
        this.updateDarkMode(false);
      }
    },
    // eslint-disable-next-line no-unused-vars
    myEventHandler(e) {
      if (this.isMobile()) {
        this.updateDarkMode(false);
      } else {
        this.updateDarkMode(false);
      }
    }
  },
  created() {
    window.addEventListener("resize", this.myEventHandler);
    this.isMobile = sharedfunctions.isMobile;
    this.updateDarkMode = sharedfunctions.updateDarkMode;
    this.initColorScheme();
  },
  destroyed() {
    window.removeEventListener("resize", this.myEventHandler);
  },

}
</script>

<style scoped>

</style>
