<template>
  <div>
    <div id="toolbar">
      <v-app-bar v-if="isMobile()" color="transparent" dense flat>
        <v-menu
            bottom
            left
        >
          <template v-slot:activator="{ on, attrs }" >
            <v-btn
                :color="color"
                icon
                v-bind="attrs"
                v-on="on"
            >
              <v-icon>{{ icons.mdiMenu }}</v-icon>
            </v-btn>
          </template>

          <v-list>
            <template
                v-for="(page, i) in pages">
              <v-list-item
                  v-if="!page.loginNecessary || isLoggedIn"
                  :key="i"
                  :to=page.path
                  text>
                {{ page.name }}
              </v-list-item>
            </template>


            <v-list-item v-if="!isLoggedIn" text @click="overlay = !overlay">Login</v-list-item>
            <v-list-item v-if="isLoggedIn" text v-on:click="logout">Logout</v-list-item>
          </v-list>
        </v-menu>
      </v-app-bar>
      <v-app-bar v-if="!isMobile()" color="transparent" dense flat>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <template
              v-for="(page, i) in pages">
            <v-btn
                v-if="!page.loginNecessary || isLoggedIn"
                :key="i"
                :to=page.path
                color="primary"
                text>
              {{ page.name }}
            </v-btn>
          </template>

          <v-btn v-if="!isLoggedIn" :color="color"  text @click="overlay = !overlay">
            <v-icon>{{ icons.mdiAccount }}</v-icon>
          </v-btn>
          <v-btn v-if="isLoggedIn" :color="color" text v-on:click="logout">
            <v-icon>{{ icons.mdiLogout }}</v-icon>
          </v-btn>
        </v-toolbar-items>
        <v-spacer/>
      </v-app-bar>

      <v-overlay :value="overlay" opacity="0.8">
        <LoginForm v-on:close="overlay = false" v-on:loggedIn="overlay = false"></LoginForm>
      </v-overlay>
    </div>
  </div>
</template>

<script>
import {mdiAccount, mdiLogout} from '@mdi/js';
import {mdiClipboardList} from '@mdi/js';
import {mdiImagePlus} from '@mdi/js';
import loginForm from '../Login/LoginForm.vue'
import {mapActions} from "vuex";
import {mdiMenu} from '@mdi/js';

export default {
  name: "Header",
  props: {
    color: String,
  },
  components: {
    'LoginForm': loginForm
  },
  data: () => ({
    icons: {
      mdiAccount,
      mdiClipboardList,
      mdiImagePlus,
      mdiLogout,
      mdiMenu
    },
    width: "64",
    overlay: false,
    pages: [
      {name: "Startseite", path: "/", loginNecessary: false},
      {name: "News", path: "/news", loginNecessary: true},
      {name: "Das Brautpaar", path: "/brautpaar", loginNecessary: true},
      {name: "Zivile Trauung", path: "/ziviletrauung", loginNecessary: true},
      // {name: "Trauzeugen", path: "/trauzeugen", loginNecessary: true},
      {name: "Nice to Know", path: "/nice-to-know", loginNecessary: true},
      {name: "Galerie", path: "/galerie", loginNecessary: true},
      {name: "Anmeldung", path: "/anmeldung", loginNecessary: true}
    ]
  }),

  methods: {
    ...mapActions(["LogOut"]),
    async logout() {
      try {
        await this.LogOut();
      } catch (error) {
        console.log(error);
      }
    },
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    }
  },

  computed: {
    isLoggedIn: function () {
      return this.$store.getters.isAuthenticated;
    }
  }

}
</script>

<style>

</style>
