<template>
  <div id="footer">
    <v-footer id="footer" padless color="transparent" class="primary--text">
      <v-container>
<!--        <v-row class="mb-6" no-gutters>
          <v-col>
            <v-card color="primary" elevation="0" class="text-center">
              <v-card-title class="justify-center">
                Kontakt
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row class="mb-6" no-gutters align="center" justify="center">
                    <v-col md="3"><v-btn class="text-none" id="link" text depressed elevation="0" plain small tile>
                      <strong> Sabrina Badilatti </strong>  +41 (0)79 266 40 64
                    </v-btn></v-col>
                    <v-col md="3"><v-btn class="text-none" id="link" text depressed elevation="0" plain small tile>
                      <strong> Silas Hirschi </strong>  +41 (0)79 935 91 82
                    </v-btn></v-col>
                  </v-row>
                </v-container>


              </v-card-text>
            </v-card>
          </v-col>

        </v-row>-->
        <v-row align="center" justify="center" >
          <v-col align-self="center"> 2021 &copy; SabrinasUndSilasHochzeit</v-col>
        </v-row>
      </v-container>
    </v-footer>
  </div>
</template>

<script>
export default {
  name: "Footer",
  data: () => ({}),
  computed: {}
};
</script>

<style>
#footer {
  font-family: Raleway;
}
</style>
